import { useMutation } from '@tanstack/react-query';
import { deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { useUser } from '@app/hooks/useUser';
import { fetcher } from '@utilities/fetcher';
import { Roles } from '@utilities/Security/roles';

export enum AuthError {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export function useLogin(redirect: string, checkRoles: Roles[] = []) {
  const [authError, setAuthError] = useState<AuthError>();
  const router = useRouter();
  const { login } = useUser();

  const mutation = useMutation(
    (data: { email: string; password: string }) => {
      setAuthError(undefined);
      deleteCookie('refresh_token');
      return fetcher('/account/login', {
        method: 'POST',
        body: JSON.stringify({
          email: data.email.toLowerCase(),
          password: data.password,
        }),
      });
    },
    {
      onSuccess: async (data, variables, context) => {
        try {
          await login(data.token, checkRoles);
          await router.push(redirect);
        } catch (e) {
          setAuthError(AuthError.NOT_ALLOWED);
        }
      },
      onError: (error) => {
        setAuthError(AuthError.INVALID_CREDENTIALS);
      },
    }
  );

  return {
    error: authError,
    login: mutation.mutate,
    loading: mutation.isLoading,
  };
}
