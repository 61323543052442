import { useApolloClient } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import { deleteCookie, setCookie } from 'cookies-next';
import jwt_decode from 'jwt-decode';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import useStore, { IUser } from '@app/store/useStore';
import { checkHttpStatusCode } from '@utilities/checkHttpStatusCode';
import { fetcher } from '@utilities/fetcher';
import { Roles } from '@utilities/Security/roles';

export function useUser() {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const { loginWithUserData, login, logout, data } = useStore((state) => state.user);

  const { mutateAsync, isLoading: isRefreshingToken } = useMutation<{ token: string; refresh_token: string }>(
    () => {
      return fetcher('/account/token/refresh', {
        method: 'POST',
      });
    },
    {
      onSuccess: async ({ token }) => {
        await doLogin(token);
      },
    }
  );

  const doLogout = useCallback(
    async (noRedirect = false, redirectTo = '/account/login') => {
      deleteCookie('nxtlvl-token');
      const response = await fetch('/account/logout', {
        credentials: 'same-origin',
      });
      if (response.ok) {
        logout();
        deleteCookie('refresh_token');
        if (!noRedirect) {
          await router.push(redirectTo);
        }
        await apolloClient.resetStore();
      }
    },
    [router, logout, apolloClient]
  );

  const doLogin = useCallback(
    async (token: string, checkRoles: Roles[] = []) => {
      setCookie('nxtlvl-token', token);
      if (checkRoles) {
        const data = jwt_decode(token) as IUser;
        if (checkRoles.every((role) => data.roles.includes(role))) {
          loginWithUserData(data);
        } else {
          await doLogout(true);
          throw new Error('Not allowed');
        }
      } else {
        login(token);
      }
    },
    [login, doLogout, loginWithUserData]
  );

  return {
    login: doLogin,
    logout: doLogout,
    refreshToken: mutateAsync,
    isRefreshingToken,
    user: data,
  };
}
